














































































































import { getRules } from '@/services/constants';
import { mapActions, mapGetters } from 'vuex';
import alertS from '../services/AlertService/AlertService';
import rService from '../services/RequestService/RequestServiceSetup';

export default {
  data: () => ({
    loading_email_tracking: false,
    loading_discord_tracking: false,
    validFormSearch: true,
    loading: false,
    dialog: false,
    loadingEntry: false,
    limits: {
      total: 0,
      limit: 0,
    },
    types: [
      {
        name: 'Email',
        value: 'email',
      },
      {
        name: 'Username',
        value: 'login',
      },
      {
        name: 'Domain',
        value: 'domain',
      },
    ],
    headers: [
      {
        text: 'Entry',
        align: 'start',
        value: 'entry',
      },
      { text: 'Type', value: 'type' },
      { text: 'Last Check', value: 'last_check', width: '190px' },
      { text: 'Last Result', value: 'last_result_date', width: '190px' },
      { text: 'Last Change Detected', value: 'changes' },
      { text: 'Change History', value: 'history', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    search: '',
    values: [],
    editedIndex: -1,
    editedItem: {
      entry: '',
      type: 'email',
    },
    defaultItem: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    }),
    enable_email_tracking: {
      get() {
        return this.userInfo.enable_email_tracking;
      },
      set(value: boolean) {
        this.loading_email_tracking = true;
        const trData = { enable_email_tracking: value, enable_discord_tracking: this.enable_discord_tracking };
        rService.post(`change_tracking_auth/${this.userInfo.platform}`, trData).then((res) => {
          if (res.success) {
            this.updateTrackingData(trData);
          } else if (res.error) {
            alertS.error(res.error);
          }
        });
      },
    },
    enable_discord_tracking: {
      get() {
        return this.userInfo.enable_discord_tracking;
      },
      set(value: boolean) {
        if (value === this.userInfo.enable_discord_tracking) return;
        this.loading_discord_tracking = true;
        const trData = { enable_discord_tracking: value, enable_email_tracking: this.enable_email_tracking };
        rService.post(`change_tracking_auth/${this.userInfo.platform}`, trData).then((res) => {
          if (res.success) {
            this.updateTrackingData(trData);
          } else if (res.error) {
            alertS.error(res.error);
            const myCheckbox: HTMLElement = document.querySelector('.discord_checkbox [role="checkbox"]');
            myCheckbox.click();
          }
        });
      },
    },
    allFields() {
      return this.editedItem.entry && this.editedItem.type;
    },
    rules: function () {
      const { generalRules, ruleTypes } = this.getRules();
      const extra = ruleTypes[this.editedItem.type];
      if (extra) {
        return [...generalRules, ...extra];
      } else {
        return [];
      }
    },
    disabled() {
      return this.limits.limit == 0 || this.limits.total >= this.limits.limit;
    },
    formTitle() {
      return this.editedIndex === -1 ? 'Add Entry' : 'Edit Entry';
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  beforeMount() {
    this.init();
  },

  created() {
    this.initialize();
  },

  methods: {
    isFound(changes: string[]) {
      return !(changes.length && changes[0] === 'Not found');
    },
    ...mapActions(['updateTrackingData']),
    timeAgo(date: string) {
      if (!date) return '';
      return this.$moment(date).fromNow();
    },
    processDate(date: string) {
      if (!date) return 'Unknown';
      return this.$moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    processChanges(changes) {
      return changes.join(', ');
    },
    getRules() {
      return getRules(this.translateHome);
    },
    translateHome(item) {
      return this.$vuetify.lang.t('$vuetify.Home.' + item);
    },
    reload() {
      this.getValues();
    },
    async getValues() {
      this.loading = true;
      const values = await rService.get('tracking/user');
      this.values = values.map((el) => {
        if (el.changes) {
          try {
            el.changes = JSON.parse(el.changes);
          } catch (e) {
            el.changes = [el.changes.toString()];
          }
        } else {
          el.changes = ['Pending Check...'];
        }
        return el;
      });
      this.loading = false;
    },
    async init() {
      this.limits = await rService.get('tracking/limit');
      this.getValues();
    },
    t(text: string) {
      return this.$vuetify.lang.t('$vuetify.Tracking.' + text);
    },
    initialize() {
      this.values = [];
    },

    editItem(item) {
      this.editedIndex = this.values.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteEntry(item) {
      const res = await rService.post('tracking/delete', item);
      this.init();
      return res;
    },

    deleteItem(item) {
      const Swal = this.$swal;
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        if (result.value) {
          Swal.showLoading();
          await this.deleteEntry(item);
          Swal.fire('Deleted!', 'The entry has been deleted from tracking.', 'success');
        }
      });
    },

    close() {
      this.dialog = false;
    },

    async saveEntry({ entry, type }) {
      const res = await rService.post('tracking/new', { entry, type });
      return res;
    },

    async save() {
      this.loadingEntry = true;
      const item = this.editedItem;
      const res = await this.saveEntry(item);
      this.loadingEntry = false;
      if (res.success) {
        this.init();
        this.close();
      }
    },
  },
};
